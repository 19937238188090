// Footer.js

import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #efeeec;
  color: #5a405b;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 280px); /* Adjust the width as per your Navbar width */
  left: 250px; /* Set to the width of your Navbar */

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  }
`;
const Footer = () => {
  return (
    <StyledFooter>
      <p>&copy; 2024 Camp JakkalsFontein. All rights reserved.</p>
    </StyledFooter>
  );
};

export default Footer;

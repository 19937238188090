import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import logo from '../../assets/logo/nameonly.png';

const NavbarContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #5a405b;
  color: #efeeec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 1000;

  @media (min-width: 768px) {
    transform: translateX(0);
  }
`;

const NavbarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem; /* Adjust this value to move the header down */
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 0; /* Remove the margin-top property */

  a {
    color: #efeeec;
    text-decoration: none;
    margin: 1rem 0;
    font-size: 1.2rem;
    padding: 0.5rem;
    border: 2px solid #fff;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  a:hover {
    color: #5a405b;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  top: 10px;
  left: 10px;
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1100; /* Ensure it's above the navbar */

  @media (min-width: 768px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
  
  a {
    color: #efeeec;
    font-size: 1.5rem;
  }

  a:hover {
    color: #5a405b;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleLinkClick = () => {
    setIsOpen(false);
  };
  return (
    <>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        ☰
      </ToggleButton>
      <NavbarContainer open={isOpen}>
        <NavbarHeader>
          <img src={logo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
        </NavbarHeader>
        <NavLinks>
          <Link to="/" onClick={handleLinkClick}>Home</Link>
          <Link to="/about" onClick={handleLinkClick}>About</Link>
          <Link to="/services" onClick={handleLinkClick}>Services</Link>
          <Link to="/map" onClick={handleLinkClick}>Map</Link>
          <Link to="/contact" onClick={handleLinkClick}>Contact</Link>
        </NavLinks>
        <SocialIcons>
          <a href="https://www.facebook.com/profile.php?id=61562243908238&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </a>
        </SocialIcons>
      </NavbarContainer>
    </>
  );
};

export default Navbar;

// src/components/Banner.js

import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo/badgeonly.png'

const BannerContainer = styled.div`
position: fixed;
top: 0;
left: 250px; /* Match the width of the navbar */
right: 0;
height: 140px;
background-color: #5a405b;
color: #efeeec;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 20px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
z-index: 1000;

@media (max-width: 768px) {
  left: 0;
  width: 100%;
}
`;

const BannerText = styled.div`
  font-family: 'Brush Script MT', cursive;
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const BannerIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BannerIcon = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

const Banner = () => {
  return (
    <BannerContainer>
      <BannerText>Camp JakkalsFontein</BannerText>
      <BannerIconContainer>
        <BannerIcon src={logo} alt="Logo" style={{ width: '250px', height: 'auto' }} />
      </BannerIconContainer>
    </BannerContainer>
  );
};

export default Banner;

import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/pictures', false, /\.(png|jpe?g|svg)$/));

const StyledHomeContent = styled.div`
  background-color: #efeeec;
  color: #5a405b;
  padding: 5px;
  text-align: center;
`;

const HomeText = styled.h3`
  font-size: 1.5rem;
`;

const CarouselWrapper = styled.div`
  margin-top: 20px;
  height: 400px; /* Set a fixed height for the carousel */
  position: relative; /* Ensure position is relative for absolute positioning of buttons */
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 1.5rem;
  z-index: 100;
`;

const NextButton = styled(CarouselButton)`
  right: 0;
`;

const PrevButton = styled(CarouselButton)`
  left: 0;
`;

const CustomCarousel = styled(Carousel)`
  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  .carousel .slide img {
    max-height: 100%; /* Ensure images fit within slide height */
    max-width: 100%; /* Ensure images fit within slide width */
    height: auto; /* Maintain aspect ratio */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Maintain aspect ratio */
  }
`;

const Home = () => {
  return (
    <>
      <StyledHomeContent>
        <HomeText>
          Camp JakkalsFontein supports aspiring artists, artisans, and creatives
          by making available open spaces to display and promote goods and services.
        </HomeText>
      </StyledHomeContent>
      <CarouselWrapper>
        <CustomCarousel showThumbs={false} autoPlay infiniteLoop>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </CustomCarousel>
      </CarouselWrapper>
    </>
  );
};

export default Home;

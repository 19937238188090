
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));


const Services = () => {
  return (
    <div>
      <div style={styles.logoContainer}>
        {images.map((image, index) => (
          <div key={index} style={styles.logoWrapper}>
            <img src={image} alt={`Image-${index + 1}`} style={styles.logos} />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  logoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns
    gap: '20px', // space between the icons
    justifyItems: 'center', // centers items horizontally in each cell
    alignItems: 'center', // centers items vertically in each cell
    marginTop: '80px'
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px', // circle diameter
    height: '80px', // circle diameter
    borderRadius: '50%', // makes the container a circle
    border: '2px solid #000', // circle border color
    padding: '10px', // padding inside the circle
    boxSizing: 'border-box', // ensures padding doesn't affect circle size
  },
  logos: {
    width: '50px', // adjust the size of the icons
    height: '50px',
  },
};

export default Services

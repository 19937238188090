import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/NavBar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Services from './components/Services/Services';
import styled from 'styled-components';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import Map from './components/Map/Map';

const MainContent = styled.div`
margin-top: 140px; /* Adjust based on the height of the banner */
margin-left: 250px; /* Match the width of the navbar */
padding: 1rem;
box-sizing: border-box;

@media (max-width: 768px) {
  margin-left: 0;
}
`;

function App() {
  return (
    <Router>
      <Banner />
      <Navbar />
      <MainContent>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/map" element={<Map />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </MainContent>
      <Footer />
    </Router>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';

const About = () => {
  const StyledHomeContent = styled.div`
  background-color: #efeeec;
  color: black;
  padding: 50px;
  text-align: center;

  @media(max-width: 768px) {
    padding: 10px;
  }
`;

  const HomeText = styled.h3`
  font-size: 1.5rem;
  text-align: center;
`;

  return (
    <StyledHomeContent>
      <HomeText>
      <p>Tulbagh is situated at the Northern end of the beautiful Tulbagh valley, which is almost completely
        surrounded by majestic mountain ranges.
      </p>
      <p>The Obiqua mountains to the west [ Beautiful sunsets ].</p>
      <p>The towering Winterhoek on the Northern end.</p>
      <p>And the Witzenberg on the Eastern side [ Where the sun rises ].</p>
    </HomeText>
    </StyledHomeContent>
  );
};

export default About;
